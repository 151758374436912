import BlueArc_FullPage from "../../assets/BlueArc_FullPage.svg";

const Home = () => {
  return (
    <div>
      <img src={BlueArc_FullPage} alt="BlueArc" />
    </div>
  );
};

export default Home;
