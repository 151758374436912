import { useEffect, useState } from "react";

import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

export function ChatPage() {
  // State to hold the dynamic iframe URL
  const [iframeSrc, setIframeSrc] = useState("https://secure.livechatinc.com/licence/15591993/open_chat.cgi");

  useEffect(
    () => {
      // Append the current URL's parameters to the iframe's base URL
      const params = window.location.search;

      if (params) {
        setIframeSrc(iframeSrc + params);
      }
    },
    // ToDo: Should be well tested.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // The empty dependency array ensures this useEffect runs once when the component mounts.
  );

  return (
    <div className="grid h-screen grid-rows-[auto,1fr,auto]">
      <PageHeader colorTheme="blue" />

      <main className="mx-auto size-full max-w-3xl py-1">
        <iframe src={iframeSrc} className="size-full rounded-lg shadow-md" title="Chat" data-testid="chat-iframe" />
      </main>

      <PageFooter displayContact={false} />
    </div>
  );
}
