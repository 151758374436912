import React from "react";
import { useLocation, useParams } from "react-router-dom";

import check_icon from "../../assets/check_icon.svg";
import QuestonIcon from "../../assets/question_mark_icon.svg";
import Aside from "../../component/Aside";
import Footer from "../../component/Footer";

interface ThankyouProps {
  apiResponse: any;
  option?: string;
  email?: string;
}

const ThankyouPage: React.FC<ThankyouProps> = ({ apiResponse }) => {
  const { option } = useParams<{ option: string }>();
  const location = useLocation();
  const emailValue = location.state?.inputValue;
  const requestor_company = apiResponse?.data?.requestor_company;
  const platform_product = apiResponse?.data?.platform_product;
  const sponsor_company = apiResponse?.data?.sponsor_company;
  sessionStorage.clear();

  const renderOptionContent = () => {
    switch (option) {
      case "AUTHORIZED":
        return (
          <>
            <div>
              <span className="text-3xl font-bold text-secondary">Thanks for your help</span>
            </div>
            <div>
              <span className="text-base font-medium text-dimgray">
                You authorized {requestor_company} to advertise for {sponsor_company} on {platform_product}.
              </span>
            </div>
          </>
        );

      case "NOTAUTHORIZED":
        return (
          <>
            <div>
              <span className="text-3xl font-bold text-secondary">Thanks for your help</span>
            </div>
            <div>
              <span className="text-base font-medium text-dimgray">
                You didn’t authorize {requestor_company} to advertise for {sponsor_company} on {platform_product}.
              </span>
            </div>
          </>
        );

      case "DELEGATE":
        return (
          <>
            <div>
              <span className="text-3xl font-bold text-secondary">Thanks for letting us know who to ask</span>
            </div>
            <div>
              <span className="text-base font-medium text-dimgray">
                We'll follow up with {emailValue} and copy you on the email.
              </span>
            </div>
          </>
        );

      case "OTHER":
        return (
          <>
            <div>
              <span className="text-3xl font-bold text-secondary">Thanks for your help</span>
            </div>
            <div>
              <span className="text-base font-medium text-dimgray">
                We’ll look at your response and may follow up with additional questions.
              </span>
            </div>
          </>
        );
      default:
        return <p>Invalid Option</p>;
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <Aside />
      <div className="flex  w-full flex-col bg-white font-hanken md:w-8/12 lg:w-8/12 xl:w-8/12">
        <div className="px-[36px] py-[48px] text-center font-hanken text-secondary md:pb-[89px] md:pl-[72px] md:pr-[288px] md:pt-[160px]">
          <div className="items-center justify-start gap-6">
            <img className="relative size-16 shrink-0 overflow-hidden" alt="check" src={check_icon} />
            <div className="items-start justify-start gap-4">{renderOptionContent()}</div>
          </div>
          <div className="h-14 shrink-0 items-center justify-center overflow-hidden rounded text-base text-primary">
            <div className="flex flex-row items-center justify-center self-stretch p-6">
              <div className="flex h-6 flex-row items-center justify-center gap-2">
                <img className="relative size-6 shrink-0 overflow-hidden" alt="question" src={QuestonIcon} />
                <a
                  data-testid="faq-button"
                  href="/faq"
                  className="relative font-hanken text-[16px] leading-6 text-primary [text-decoration:none]"
                >
                  Check our FAQs
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="relative md:top-80 md:w-[600px] md:pl-20">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouPage;
