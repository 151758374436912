import axios, { type AxiosResponse, type InternalAxiosRequestConfig } from "axios";

import { firebaseAuth } from "../lib/firebase/auth";

const HttpService = axios.create({
  timeout: 10000,
});

HttpService.interceptors.request.use(
  async (request: InternalAxiosRequestConfig) => {
    const token = await firebaseAuth.currentUser?.getIdToken();

    if (!!token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

HttpService.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default HttpService;
