import { FC } from "react";
import { type MouseEventHandler } from "react";
import { GoArrowDown } from "react-icons/go";

import { Button } from "../../../components/ui/button";
import { SCORE_CATEGORIES } from "../../../constants/risk-summary";
import { compactJoin } from "../../../lib/utils";
import type { BusinessScoresResponse, ScoreCategoryKey } from "../../../Services/Types";
import { isNumber } from "../../../Utils/risk-summary";
import { RiskScore } from "../../ui/risk-score";

export type ScoreCategoriesTickersProps = {
  dataBusinessScores: BusinessScoresResponse;
  onScrollToSection: (section: ScoreCategoryKey) => MouseEventHandler<HTMLButtonElement>;
};

export const ScoreCategoriesTickers: FC<ScoreCategoriesTickersProps> = ({ dataBusinessScores, onScrollToSection }) => (
  <div className="grid grid-cols-2 gap-3 md:col-span-2 md:grid-cols-3 lg:grid-cols-5">
    {Object.entries(SCORE_CATEGORIES).map(([key, category]) => {
      const categoryKey = key as ScoreCategoryKey;
      const sectionDetails = dataBusinessScores.v2?.detailed_scores?.find(
        (detailedScore) => detailedScore.section_title === categoryKey,
      );

      if (!sectionDetails || !isNumber(sectionDetails?.section_score)) {
        return null;
      }

      const isSectionLeadershipPresence = sectionDetails?.section_title === "LEADERSHIP_PRESENCE";
      // @ts-ignore
      const details = isSectionLeadershipPresence ? sectionDetails.person_details : sectionDetails.details;

      return (
        <div
          key={compactJoin(["detailed", "score", "block", key], "_")}
          className="grid content-between justify-items-center gap-5 rounded-xl border bg-white p-5"
        >
          <header className="flex w-full justify-between gap-2">
            <h3 className="text-base font-bold text-theme-text-body">{category.title}</h3>

            {/* ToDo: Refactor this icon button after #62 will be merged */}
            {!!details?.length && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="shrink-0"
                onClick={onScrollToSection(categoryKey)}
              >
                <GoArrowDown className="size-4" />
              </Button>
            )}
          </header>

          <RiskScore size="md" score={sectionDetails?.section_score} />
        </div>
      );
    })}
  </div>
);
