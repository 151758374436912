import { FC, type MouseEventHandler } from "react";

import { RISKS, SCORE_CATEGORIES } from "../../../constants/risk-summary";
import useLayoutVersion from "../../../hooks/useLayoutVersion";
import { classNames, compactJoin } from "../../../lib/utils";
import type {
  BusinessScoresDetailedScoresLeadershipPresence,
  BusinessScoresResponse,
  ScoreCategoryKey,
} from "../../../Services/Types";
import { groupDataByAttribute, isNumber } from "../../../Utils/risk-summary";
import { getRiskScoreStatus } from "../../ui/risk-score";
import { LeadershipPresenceSection } from "./leadership-presence";
import { ScoreCategoriesVariants } from "./score-categories-variants";

const QUERY_SELECTOR_SECTION = "data-section-category-details";

export type ScoreCategoriesProps = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  copiedText: string;
  dataBusinessScores: BusinessScoresResponse;
  isGroupedDetailsExpanded: (title: string) => boolean;
  onToggleExpandedDetailsBlock: (title: string) => void;
};

export const ScoreCategories: FC<ScoreCategoriesProps> = ({
  tooltipId,
  onCopyLink,
  copiedText,
  dataBusinessScores,
  onToggleExpandedDetailsBlock,
  isGroupedDetailsExpanded,
}) => {
  const { version } = useLayoutVersion();

  // @ts-ignore
  const newLayout = version === 2;

  return (
    <>
      {Object.entries(SCORE_CATEGORIES).map(([key, category]) => {
        const categoryKey = key as ScoreCategoryKey;
        const sectionDetails = dataBusinessScores.v2?.detailed_scores?.find(
          (detailedScore) => detailedScore.section_title === categoryKey,
        );

        if ((!sectionDetails || !isNumber(sectionDetails?.section_score)) && !newLayout) {
          return null;
        }

        const isSectionLeadershipPresence = sectionDetails?.section_title === "LEADERSHIP_PRESENCE";
        // TODO: domanskyi - fix typing
        // @ts-ignore
        const details = isSectionLeadershipPresence ? sectionDetails?.person_details : sectionDetails?.details;

        const scoreStatus = sectionDetails?.section_score
          ? getRiskScoreStatus(sectionDetails?.section_score)
          : "unknown";

        if (!details?.length && !newLayout) {
          return null;
        }

        const groupedDetails =
          // @ts-ignore
          sectionDetails?.details && !isSectionLeadershipPresence ? groupDataByAttribute(sectionDetails?.details) : {};

        const groupedDetailV2 = newLayout
          ? // @ts-ignore
            dataBusinessScores.find((section) => section.section_name === categoryKey)
          : groupedDetails;

        if (newLayout && !groupedDetailV2) {
          return null;
        }

        return (
          <div
            key={compactJoin(["detailed", "score", "section", key], "_")}
            className="relative grid scroll-mt-40 content-start gap-8 rounded-2xl border bg-white p-8 md:col-span-2"
            {...{
              id: key,
              [QUERY_SELECTOR_SECTION]: true,
            }}
          >
            <h2 className="my-0.5 text-2xl font-bold text-theme-text-body">{category.title}</h2>

            {sectionDetails?.section_score && (
              <span
                className={classNames(
                  "absolute right-0 top-8 flex h-9 w-28 items-center justify-center rounded-s-full text-xl font-bold text-white",
                  RISKS[scoreStatus].backgroundColor,
                )}
              >
                {sectionDetails?.section_score}
              </span>
            )}

            {isSectionLeadershipPresence ? (
              <LeadershipPresenceSection
                sectionDetails={sectionDetails as BusinessScoresDetailedScoresLeadershipPresence}
                tooltipId={tooltipId}
                onCopyLink={onCopyLink}
              />
            ) : (
              <ScoreCategoriesVariants
                groupedDetails={groupedDetailV2}
                tooltipId={tooltipId}
                onCopyLink={onCopyLink}
                copiedText={copiedText}
                onToggleExpandedDetailsBlock={onToggleExpandedDetailsBlock}
                isGroupedDetailsExpanded={isGroupedDetailsExpanded}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
