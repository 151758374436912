import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useLayoutVersion = (): {
  version: number;
} => {
  const [searchParams] = useSearchParams();

  const version = useMemo(() => {
    const qVersion = searchParams.get("v");

    if (!!qVersion) {
      return +qVersion;
    }

    return 1;
  }, [searchParams]);

  return {
    version,
  };
};

export default useLayoutVersion;
