import { getAuth, GoogleAuthProvider } from "firebase/auth";

import { firebaseApp } from "./app";

export const firebaseAuth = getAuth(firebaseApp);

export const firebaseAuthProviders = {
  google: new GoogleAuthProvider(),
};

export {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
