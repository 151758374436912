import { FC, type MouseEventHandler } from "react";

import { ShowMoreButton } from "../../../extra/show-more-button";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../ui/accordion";
import DetailRow from "../../detail-row";

export type ScoreCategoriesV3Props = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  copiedText: string;
  // TODO: domanskyi - fix typing!!!!!!!!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedDetails: any;
  isGroupedDetailsExpanded: (title: string) => boolean;
  onToggleExpandedDetailsBlock: (title: string) => void;
};

const parseObjectValue = (value: unknown): string => {
  if (typeof value === "object") {
    // @ts-ignore
    return Object.values(value).join(", ");
  }

  return value as string;
};

export const ScoreCategoriesV3: FC<ScoreCategoriesV3Props> = ({
  groupedDetails,
  isGroupedDetailsExpanded,
  onToggleExpandedDetailsBlock,
}) => {
  return (
    <div className="grid gap-8">
      {// @ts-ignore
      groupedDetails?.section_attributes.map((section_attribute) => {
        return (
          <div className="relative grid gap-3 border-b pb-8 pl-64 last:border-none last:pb-0">
            <h4 className="absolute left-0 my-1.5 max-w-60 text-base font-bold text-theme-text-body">
              {section_attribute.attribute_name} ({section_attribute.num_values})
            </h4>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
              }}
            >
              {(isGroupedDetailsExpanded(section_attribute.attribute_name)
                ? section_attribute.values
                : section_attribute.values.slice(0, 5)
              ) // @ts-ignore
                .sort((a, b) => {
                  return b.sources.length - a.sources.length;
                }) // @ts-ignore
                .map((value) => {
                  const sources = value.sources;

                  if (sources.length === 1) {
                    return (
                      <div
                        className="text-sm font-medium"
                        style={{
                          paddingTop: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {
                          // @ts-ignore
                          sources.map((source) => (
                            <DetailRow
                              // @ts-ignore
                              detail={{
                                // @ts-ignore
                                source_url: source.url,
                                visible_value: parseObjectValue(source.value),
                                value: parseObjectValue(source.visible),
                              }}
                              getCopyLinkText={() => null}
                              tooltipId={`${source.value}` as string}
                            />
                          ))
                        }
                      </div>
                    );
                  }

                  console.log(value.value);

                  return (
                    <div>
                      <Accordion type="single" collapsible>
                        <AccordionItem value={parseObjectValue(value.value)}>
                          <AccordionTrigger chevronPosition="start">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingLeft: "0.25rem",
                              }}
                            >
                              <span>{parseObjectValue(sources[0]?.value)}</span>{" "}
                              <span>{`${sources.length} ${sources.length === 1 ? "source" : "sources"}`}</span>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div
                              style={{
                                paddingLeft: "20px",
                              }}
                            >
                              {
                                // @ts-ignore
                                sources.map((source) => (
                                  <DetailRow
                                    // @ts-ignore
                                    detail={{
                                      // @ts-ignore
                                      source_url: source.url,
                                      visible_value: parseObjectValue(source.value),
                                      value: parseObjectValue(source.visible),
                                    }}
                                    getCopyLinkText={() => null}
                                    tooltipId={`${source.value}` as string}
                                  />
                                ))
                              }
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  );
                })}
            </div>
            {section_attribute.num_values > 5 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <ShowMoreButton
                  onClick={() => onToggleExpandedDetailsBlock(section_attribute.attribute_name)}
                  isShowingMore={isGroupedDetailsExpanded(section_attribute.attribute_name)}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
