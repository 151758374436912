import { FC } from "react";

import ImageNotFound from "../../../assets/not-found-error.svg";
import { compactJoin } from "../../../lib/utils";
import type { BusinessScoresResponse } from "../../../Services/Types";

export type AboutTheBusinessProps = {
  dataBusinessScores: BusinessScoresResponse;
};

export const AboutTheBusiness: FC<AboutTheBusinessProps> = ({ dataBusinessScores }) => (
  <div className="grid content-start gap-5 rounded-2xl border bg-white p-8">
    <h2 className="mb-2 text-2xl font-bold text-theme-text-body">About the Business</h2>

    <div className="grid grid-cols-[auto,1fr] items-start gap-5">
      <figure className="row-span-2 size-28 overflow-hidden rounded-md border bg-[#FAF6E7]">
        <img
          src={ImageNotFound}
          className="size-full object-cover"
          alt={compactJoin([dataBusinessScores.v2?.about?.name, "Business Logo"])}
        />
      </figure>

      {!!dataBusinessScores.v2?.about?.name && (
        <div className="grid">
          <span className="text-sm font-medium text-theme-text-input">Name</span>
          <p className="text-base font-normal text-theme-text-body">{dataBusinessScores.v2.about.name}</p>
        </div>
      )}

      {!!dataBusinessScores.v2?.about?.url && (
        <div className="mt-auto grid">
          <span className="text-sm font-medium text-theme-text-input">Business URL</span>
          <div className="flex">
            <a
              href={dataBusinessScores.v2.about.url}
              className="text-base font-normal text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {dataBusinessScores.v2.about.url}
            </a>
          </div>
        </div>
      )}
    </div>

    {!!dataBusinessScores.v2?.about?.summary && (
      <p className="text-base font-normal text-theme-text-body">{dataBusinessScores.v2.about.summary}</p>
    )}
  </div>
);
