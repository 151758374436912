import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

export default function Faq() {
  return (
    <div className="bg-neutral-50 text-left font-hanken text-base text-secondary">
      <PageHeader pageTitle="FAQs" pageSubTitle="Frequently asked questions" />

      <div className="flex grow flex-row px-6 pt-8 md:px-[10%] md:pt-12">
        <div className="flex w-full flex-col items-start justify-start text-xl leading-7 md:w-[48%]">
          <b className="relative">Who we are, and why we reached out to you</b>

          <div className="flex flex-col items-start justify-start pt-6 text-base leading-6 md:pt-6">
            <div className="relative inline font-medium">{`We understand you may have questions about the verification process we asked you to assist us with. Here are answers to common questions. `}</div>
          </div>
        </div>
      </div>

      <div className="flex w-full grow flex-row md:clear-both">
        <div className="flex w-full flex-wrap px-6 pt-8 md:gap-x-[4%] md:px-[10%] md:pt-12">
          <div className="flex w-full flex-col items-start justify-start gap-8 text-xl leading-8 md:w-[48%] md:gap-8">
            <Accordion
              type="single"
              collapsible={true}
              defaultValue="about-blue-arc-and-its-partners"
              className="w-full rounded border border-teal-100 bg-white"
            >
              <AccordionItem value="about-blue-arc-and-its-partners">
                <AccordionTrigger className="bg-whitesmoke p-6 text-xl font-bold hover:no-underline">
                  About BlueArc and its partners
                </AccordionTrigger>

                <AccordionContent>
                  <div className="flex flex-col items-start justify-start gap-2 p-6 py-0 text-base md:gap-2 md:py-6">
                    <b className="relative leading-6">What is BlueArc?</b>

                    <div className="relative leading-6">
                      BlueArc is a verification and risk management provider that assists Google Ads to serve good
                      businesses better and restrict bad actors.
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">Who are your partners?</b>

                    <div className="relative leading-6">
                      BlueArc is contracted with Google Ads to verify some of their business customers. While most
                      advertisers act in good faith, some bad actors misrepresent themselves as well-known brands to
                      defraud end users. These bad actors harm end-users, the reputation of Google, and the affected
                      brands. BlueArc helps Google distinguish the good advertisers from the bad actors.
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <Accordion
              type="single"
              collapsible={true}
              defaultValue="about-information-sharing"
              className="w-full rounded border border-teal-100 bg-white"
            >
              <AccordionItem value="about-information-sharing">
                <AccordionTrigger className="bg-whitesmoke p-6 text-xl font-bold hover:no-underline">
                  About information sharing
                </AccordionTrigger>

                <AccordionContent>
                  <div className="flex flex-col items-start justify-start gap-2 p-6 py-0 text-base md:gap-2 md:py-6">
                    <b className="relative leading-6">Who do you share this information with?</b>

                    <div className="relative leading-6">
                      Your response will become part of our verification record for the advertiser, and we share it with
                      them. We also share details of our verification record with Google so they can make better
                      onboarding, policy, and risk management decisions for the advertiser. If the advertiser wants us
                      to do so, we will also share their verification record with other platforms they partner with.
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="flex w-full flex-col items-start justify-start gap-8 rounded border border-neutral-50 pt-8 md:w-[48%] md:gap-8 md:pt-0">
            <Accordion
              type="single"
              collapsible={true}
              defaultValue="about-the-verification-process"
              className="w-full rounded border border-teal-100 bg-white"
            >
              <AccordionItem value="about-the-verification-process">
                <AccordionTrigger className="bg-whitesmoke p-6 text-xl font-bold hover:no-underline">
                  About the verification process
                </AccordionTrigger>

                <AccordionContent>
                  <div className="flex flex-col items-start justify-start gap-2 p-6 py-0 text-base md:gap-2 md:py-6">
                    <b className="relative leading-6">Why are you reaching out to me?</b>

                    <div className="relative leading-6">
                      Our goal is to protect your brand against misrepresentation. When an advertiser claims they can
                      represent your brand, we want to double-check that they are truthful. If they are under contract
                      to promote your brand or generate leads, confirming their relationship allows them to advertise on
                      your behalf. If they don't have a relationship with you, letting us know will enable Google to
                      protect your brand against misuse.
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">
                      Are you asking me to guarantee the good behavior of an advertiser?
                    </b>

                    <div className="relative leading-6">
                      No, we're not asking you to guarantee their good behavior. If an advertiser told us they could
                      advertise or provide leads on your behalf, we only ask you to verify they are authorized.
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">Am I legally liable for the reliability of the advertiser?</b>

                    <div className="relative leading-6">No, you're not liable.</div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">What if we later terminate the contract with the advertiser?</b>

                    <div className="relative leading-6">
                      <span>{`You can always review and manage your authorizations on your `}</span>

                      <b className="text-primary [text-decoration:underline]">
                        Manage your business authorizations page
                      </b>

                      <span>
                        . We will update the advertiser's verification record and inform them of the change. We will
                        also notify Google of any changes.
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">
                      What if we have a business relationship but don't want them to advertise on Google?
                    </b>

                    <div className="relative leading-6">
                      If your contract prevents them from advertising on Google, simply select the option that they are
                      not authorized to market your brand.
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-2 px-6 py-0 pb-6 text-base md:gap-2 md:pb-6">
                    <b className="relative leading-6">
                      What if someone else is the right person to verify the advertiser?
                    </b>

                    <div className="relative leading-6">
                      Select the option "I'm not the right person to ask," and let us know who is. We'll follow up with
                      them.
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>

      <PageFooter />
    </div>
  );
}
