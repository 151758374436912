import { FunctionComponent } from "react";

import BlueArc_logo_with_text from "../../assets/BlueArc_logo_with_text.svg";
import pattern from "../../assets/pattern.svg";

const Aside: FunctionComponent = () => {
  return (
    <aside
      id="default-sidebar"
      className="sx:w-full sx:h-40 bg-secondary sm:h-40 sm:w-full md:h-[1024px] lg:h-[1024px] lg:w-4/12"
      aria-label="Sidebar"
    >
      <div className="sx:flex sx:h-32 w-full justify-center sm:flex sm:h-32 sm:text-center md:h-[1024px] md:flex-col lg:h-[1024px] lg:flex-col">
        <div className="sx:h-20 !sx:justify-center inline-flex w-full justify-center sm:h-20 md:relative md:h-2/6 md:flex-col lg:relative lg:h-2/6 lg:flex-col">
          <img className="bottom-6 right-0 float-right lg:absolute" src={BlueArc_logo_with_text} alt="logo" />
        </div>
        <div
          className="bg-secondary md:visible md:h-4/6 lg:visible lg:h-4/6"
          style={{
            backgroundImage: `url(${pattern})`,
          }}
        ></div>
      </div>
    </aside>
  );
};

export default Aside;
