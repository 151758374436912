import { useCallback, useState } from "react";

type CopiedValue = string | null;

type CopyFunction = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopiedValue, CopyFunction] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const onCopyToClipboard: CopyFunction = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);

      setCopiedText(text);

      return true;
    } catch (error) {
      setCopiedText(null);

      return false;
    }
  }, []);

  return [copiedText, onCopyToClipboard];
}
