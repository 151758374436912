import Aside from "../../component/Aside";
import Footer from "../../component/Footer";
import MainContainer from "../../component/MainContainer";

const Layout = ({ apiResponse }: any) => {
  return (
    <div className="flex h-screen flex-col md:flex-row">
      <Aside data-testid="aside" />
      <div className="caret-transparent md:p-12">
        <MainContainer apiResponse={apiResponse} data-testid="main-container" />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
