import { FC } from "react";

import { RISKS } from "../../../constants/risk-summary";
import { classNames, compactJoin } from "../../../lib/utils";
import type { BusinessScoresResponse } from "../../../Services/Types";
import { RiskScore } from "../../ui/risk-score";

export type TotalRiskScoreProps = {
  dataBusinessScores: BusinessScoresResponse;
};

export const TotalRiskScore: FC<TotalRiskScoreProps> = ({ dataBusinessScores }) => (
  <div className="grid content-start gap-5 rounded-2xl border bg-white p-8">
    <h2 className="mb-2 text-2xl font-bold text-theme-text-body">Total Risk Score</h2>

    <div className="flex flex-col items-center gap-5 lg:flex-row">
      <RiskScore
        size="lg"
        score={dataBusinessScores.v2?.total_risk_score?.overall_score ?? dataBusinessScores.overall_score}
      />

      <div className="grid gap-3">
        {Object.entries(RISKS).map(([key, risk]) => (
          <div
            key={compactJoin(["total", "risk", "score", key], "_")}
            className={classNames(
              "relative grid gap-1 pl-4 text-sm font-bold",
              "before:absolute before:left-0 before:top-1.5 before:block before:size-2 before:rounded-full",
              risk.beforeBackgroundColor,
            )}
          >
            {risk.title}
            <p className="text-xs font-normal text-theme-text-body">{risk.description}</p>
          </div>
        ))}
      </div>
    </div>

    {!!dataBusinessScores.v2?.total_risk_score?.summary && (
      <p className="text-base font-normal text-theme-text-body">{dataBusinessScores.v2.total_risk_score.summary}</p>
    )}
  </div>
);
