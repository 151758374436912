import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { classNames } from "../../lib/utils";

const labelVariants = cva(
  "flex text-base font-bold text-theme-text-body peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={classNames(labelVariants(), className)} {...props} />
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
