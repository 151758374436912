import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import rightAlert from "../../assets/IconRight.svg";
import question_mark_icon from "../../assets/question_mark_icon.svg";
import checkedRight from "../../assets/Vector.svg";
import { registerVoucher } from "../../Services/Services";
import { buildApiURLQuery } from "../../Utils/utility";

interface MainContainerProps {
  apiResponse: any;
}

const MainContainer: React.FC<MainContainerProps> = ({ apiResponse }) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [otherValue, setOtherValue] = useState<string>("");

  const navigate = useNavigate();
  sessionStorage.getItem("token_id");
  const isValidEmail = (email: string) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    localStorage.setItem("selectedValue", event.target.value);
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const handleChangeOther = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherValue(event.target.value);
  };

  useEffect(() => {
    // Retrieve the stored value from localStorage on component mount
    const storedValue = localStorage.getItem("selectedValue");
    if (storedValue) {
      setSelectedOption(storedValue);
    }
  }, []);
  const onSubmit = async () => {
    try {
      let isFormValid = true;

      if (selectedOption === "") {
        // alert("Please select an option.");
        isFormValid = false;
      } else if (selectedOption === "DELEGATE" && (!isValidEmail(inputValue) || inputValue.trim() === "")) {
        //alert("Please enter a valid email address.");
        isFormValid = false;
      } else if (selectedOption === "OTHER" && otherValue.trim() === "") {
        /// alert("Please provide a brief description.");
        isFormValid = false;
      }

      if (!isFormValid) {
        return;
      }

      const query = buildApiURLQuery({
        token_id: sessionStorage.getItem("token_id"),
        relcode: `${selectedOption}`,
        delegate_email: selectedOption === "DELEGATE" ? `${inputValue}` : null,
        other: selectedOption === "OTHER" ? `${otherValue}` : null,
      });

      const response = await registerVoucher({ queryParam: query });

      if (response && response.status === 200) {
        navigate(`/thankyoupage/${selectedOption}`, { state: { inputValue } });
      } else if (response && response.status === 400) {
        navigate("/api-error");
      }
    } catch (ex) {
      console.error("Error:", ex);
      navigate("/not-found-error");
    }
  };

  return (
    <div>
      <div className="my-12 ml-12 h-[242px] w-[323px] md:h-[709px] md:w-[656px]">
        <div
          data-testid="main"
          className="h-[114px] w-[323px] font-hanken text-[30px]  font-bold leading-[38px] caret-transparent md:h-[92px] md:w-[656px] md:text-[36px] md:font-bold md:leading-[46px]"
        >
          <span className="font-bold">{`Is `}</span>
          <span className="font-black">{apiResponse?.data?.requestor_company} </span>
          <span className="font-bold">authorized to advertise for {apiResponse?.data?.sponsor_company}?</span>
        </div>
        <div
          data-testid="main2"
          className="mt-20 h-[120px] w-[323px] break-normal font-hanken text-[16px] font-medium leading-[24px] text-dimgray caret-transparent md:mt-12 md:h-[72px] md:w-[656px]"
        >
          <p>
            We’ll share your response with {apiResponse?.data?.requestor_company}. We’ll also <br />
            share the information with {apiResponse?.data?.platform_company} so they can <br />
            better serve good businesses and protect your brand.
          </p>
        </div>
        <form>
          <div className="mt-8 w-10/12">
            <div className="flex">
              <label htmlFor="helper-radio">
                <div className="flex h-5 items-center">
                  <input
                    id="helper-radio"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    data-testid="AUTHORIZED"
                    value="AUTHORIZED"
                    name="AUTHORIZED"
                    checked={selectedOption === "AUTHORIZED"}
                    onChange={handleChange}
                    className="mt-2 cursor-pointer caret-transparent  accent-teal-100 focus:accent-teal-100 focus:ring-0 focus:ring-white"
                  />
                </div>
                <div className="-mt-5 ml-2 pl-6 text-sm">
                  <label
                    data-testid="radio1"
                    htmlFor="helper-radio"
                    className="font-hanken text-[16px]  font-bold leading-[24px] text-secondary caret-transparent  md:text-[20px] md:leading-[28px]"
                  >
                    Authorized
                  </label>
                  <p
                    id="helper-radio-text"
                    data-testid="radio-1"
                    className="font-hanken text-[16px] font-normal leading-[24px] text-black"
                  >
                    <label htmlFor="helper-radio">
                      We have a business relationship with {apiResponse?.data?.requestor_company}, and they are
                      authorized to market our brand.
                    </label>
                  </p>
                </div>
              </label>
            </div>
            <div className="flex">
              <label htmlFor="helper-radio1">
                <div className="flex h-5 items-center">
                  <input
                    id="helper-radio1"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    data-testid="NOTAUTHORIZED"
                    name="NOTAUTHORIZED"
                    value="NOTAUTHORIZED"
                    checked={selectedOption === "NOTAUTHORIZED"}
                    onChange={handleChange}
                    className="mt-2 cursor-pointer caret-transparent accent-teal-100 focus:accent-teal-100 focus:ring-0 focus:ring-white"
                  />
                </div>
                <div className="-mt-5 ml-2 pl-6 text-sm">
                  <label
                    data-testid="radio2"
                    htmlFor="helper-radio1"
                    className="font-hanken text-[16px] font-bold leading-[24px] text-secondary caret-transparent  md:text-[20px] md:leading-[28px]"
                  >
                    Not authorized
                  </label>
                  <p
                    id="helper-radio-text1"
                    data-testid="radio-2"
                    className="font-hanken text-[16px] font-normal leading-[24px] text-black"
                  >
                    <label htmlFor="helper-radio1">
                      We do not have a business relationship with {apiResponse?.data?.requestor_company} that authorizes
                      them to market our brand.
                    </label>
                  </p>
                </div>
              </label>
            </div>
            <div className="flex">
              <label htmlFor="helper-radio2">
                <div className="flex h-5 items-center">
                  <input
                    id="helper-radio2"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    value="DELEGATE"
                    data-testid="DELEGATE"
                    checked={selectedOption === "DELEGATE"}
                    onChange={handleChange}
                    className="mt-2 cursor-pointer accent-teal-100 focus:accent-teal-100 focus:ring-0 focus:ring-white"
                  />
                </div>
                <div className="-mt-5 ml-2 pl-6 text-sm">
                  <label
                    data-testid="radio3"
                    htmlFor="helper-radio2"
                    className="font-hanken text-[16px]  font-bold leading-[24px] text-secondary caret-transparent  md:text-[20px] md:leading-[28px]"
                  >
                    I’m not the right person to ask. Please reach out to my colleague:
                  </label>
                  <div className="relative flex flex-row">
                    <input
                      type="email"
                      value={inputValue}
                      onChange={handleChangeInput}
                      placeholder="Enter their email address"
                      required={selectedOption === "DELEGATE"}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      className={
                        selectedOption === "DELEGATE" && !isValidEmail(inputValue)
                          ? "border-0 border-none bg-neutral-50 font-hanken leading-[24px] outline-none placeholder:text-[16px] placeholder:font-normal placeholder:text-[#84838D] focus:ring-1 focus:ring-[#BD3636] md:w-[70%]"
                          : "border-0 border-none bg-neutral-50 font-hanken leading-[24px] outline-none placeholder:text-[16px] placeholder:font-normal placeholder:text-[#84838D] focus:ring-1 focus:ring-white md:w-[70%]"
                      }
                      id="exampleFormControlInput4"
                    />

                    {selectedOption === "DELEGATE" && !isValidEmail(inputValue) && (
                      <div className="absolute left-3/4 mt-2 md:left-[65%]">
                        <img src={rightAlert} alt="rightAlert" />
                      </div>
                    )}
                    {selectedOption === "DELEGATE" && isValidEmail(inputValue) && (
                      <div className="absolute left-[45%] mt-4 md:left-[65%]">
                        <img src={checkedRight} alt="rightAlert" />
                      </div>
                    )}
                  </div>

                  {selectedOption === "DELEGATE" && !isValidEmail(inputValue) && (
                    <div className="font-hanken text-[12px] font-extralight text-[#84838D] caret-transparent">
                      Double-check you entered a valid email address
                    </div>
                  )}
                </div>
              </label>
            </div>
            <div className="flex">
              <label htmlFor="helper-radio3">
                <div className="flex h-5 items-center">
                  <input
                    id="helper-radio3"
                    aria-describedby="helper-radio-text"
                    type="radio"
                    value="OTHER"
                    data-testid="OTHER"
                    checked={selectedOption === "OTHER"}
                    onChange={handleChange}
                    className="mt-2 cursor-pointer  accent-teal-100 focus:accent-teal-100 focus:ring-0 focus:ring-white"
                  />
                </div>
              </label>
              <div className="ml-2 w-full  ">
                <label
                  data-testid="radio4"
                  htmlFor="helper-radio3"
                  className="font-hanken  text-[16px] font-bold leading-[24px] text-secondary  md:text-[20px] md:leading-[28px] "
                >
                  Other (please explain)
                  <div className="relative w-full">
                    <textarea
                      id="message"
                      className="my-2 w-full resize-none border-0 bg-neutral-50 pt-2 font-hanken font-normal leading-[24px] outline-none  placeholder:pl-2 placeholder:text-[16px] placeholder:text-[#84838D] focus:border-primary"
                      rows={6}
                      cols={20}
                      value={otherValue}
                      placeholder="Briefly describe your business relationship"
                      onChange={handleChangeOther}
                      required={selectedOption === "OTHER"}
                    />

                    {selectedOption === "OTHER" && otherValue.trim() === "" && (
                      <div className="absolute bottom-[70%] left-[85%] md:left-[95%] ">
                        <img src={rightAlert} alt="rightAlert" />
                      </div>
                    )}
                    {selectedOption === "OTHER" && otherValue.trim() !== "" && (
                      <div className="absolute bottom-[70%] left-[85%] md:left-[95%] ">
                        <img src={checkedRight} alt="rightAlert" />
                      </div>
                    )}
                  </div>
                  {/* Add an error message */}
                  {/* {selectedOption === "OTHER" && otherValue.trim() === "" && (
                <div className="error" data-testid="radio-4">Please provide a brief description.</div>
              )} */}
                </label>
              </div>
            </div>
            <div className="sx:flex-col mt-2 flex-col items-start justify-between self-stretch text-center  text-base text-primary md:mt-8 md:w-[572px] lg:flex lg:flex-row">
              <div className="sx:w-full hidden h-14 shrink-0 flex-col items-center justify-center overflow-hidden rounded sm:w-full md:flex md:w-full lg:w-[216px]">
                <div className="flex flex-row items-center justify-center self-stretch p-4">
                  <a href="/faq">
                    <div className="flex h-6 flex-row items-center justify-center gap-[8px]">
                      <img className="relative size-6 shrink-0 overflow-hidden" alt="" src={question_mark_icon} />
                      <p
                        data-testid="faq-button"
                        className="relative font-hanken text-[16px] font-bold leading-[24px] text-primary caret-transparent"
                      >
                        Check our FAQs
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <div
                className="sx:w-full flex shrink-0 flex-col items-center justify-center overflow-hidden rounded text-neutral-50 sm:w-full md:w-full lg:w-[216px]"
                onClick={onSubmit}
              >
                <div className="flex flex-row items-center justify-center self-stretch rounded bg-primary px-6 py-4">
                  <div className="flex h-6 flex-row items-center justify-center">
                    <button type="button" value="Submit" data-testid="Submit" className="cursor-pointer bg-primary">
                      <span
                        data-testid="sub-button"
                        className="h-[24px] w-[36px] text-[16px] font-bold leading-[24px] text-white caret-transparent"
                      >
                        Send
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="sx:w-full mb-2 flex h-14 shrink-0 flex-col items-center justify-center overflow-hidden rounded sm:w-full md:hidden md:w-full lg:w-[216px]">
                <div className="flex flex-row items-center justify-center self-stretch px-6 py-4">
                  <div className="flex h-6 flex-row items-center justify-center gap-[8px]">
                    <img className="relative size-6 shrink-0 overflow-hidden" alt="what" src={question_mark_icon} />
                    <p className="relative font-hanken text-[16px] font-bold leading-[24px] text-primary caret-transparent">
                      Check our FAQs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MainContainer;
