import type { SVGProps } from "react";

export type IconProps = Omit<SVGProps<SVGSVGElement>, "children">;

export function IconCross(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
      />
    </svg>
  );
}

export function IconLink(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.657 3.343a3 3 0 0 0-4.243 0L9 4.757a1 1 0 0 1-1.414-1.414L9 1.929A5 5 0 1 1 16.07 9l-1.413 1.414A1 1 0 1 1 13.243 9l1.413-1.414a3 3 0 0 0 0-4.243Zm-2.121 2.122a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l5.656-5.657a1 1 0 0 1 1.415 0Zm-7.778 2.12a1 1 0 0 1 0 1.415l-1.414 1.414a3 3 0 1 0 4.242 4.243L9 13.243a1 1 0 0 1 1.415 1.413L9 16.072A5.001 5.001 0 0 1 1.93 9l1.414-1.415a1 1 0 0 1 1.414 0Z"
      />
    </svg>
  );
}

export function IconFile(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.162 0h9.677c.527 0 .981 0 1.356.03.395.033.788.104 1.166.297a3 3 0 0 1 1.312 1.31c.193.379.264.772.297 1.167.03.374.03.828.03 1.354v5.517l-.001.334a3.751 3.751 0 0 1-.082.863c-.074.306-.196.6-.36.867-.188.308-.442.562-.734.853l-.053.053-4.125 4.125-.056.056c-.29.29-.543.544-.85.732a3 3 0 0 1-.867.36c-.292.07-.571.079-.863.081L9.68 18H4.158c-.526 0-.98 0-1.354-.03-.395-.033-.788-.104-1.166-.297a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167C0 14.821 0 14.365 0 13.84V4.162c0-.527 0-.982.03-1.357.033-.395.104-.789.297-1.167A2.999 2.999 0 0 1 1.637.327c.38-.193.773-.264 1.168-.296C3.18 0 3.635 0 4.162 0ZM2.968 2.024c-.272.022-.373.06-.422.085a.999.999 0 0 0-.437.437c-.025.05-.063.15-.085.422C2 3.25 2 3.624 2 4.2v9.6c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 0 0 .437.437c.049.025.15.063.42.085.284.023.655.024 1.23.024H9v-4.432c0-.252 0-.498.017-.706.019-.229.063-.499.201-.77a2 2 0 0 1 .874-.874c.271-.138.54-.182.77-.201C11.07 9 11.314 9 11.567 9H16V4.197c0-.576 0-.947-.024-1.23-.022-.271-.06-.372-.085-.421a1 1 0 0 0-.438-.437c-.05-.025-.15-.063-.421-.085C14.749 2 14.377 2 13.8 2H4.2c-.576 0-.949 0-1.232.024ZM15.584 11H11.6c-.297 0-.46 0-.576.01l-.012.001-.002.014c-.01.116-.01.279-.01.575v3.984c.062-.06.137-.135.23-.228l4.126-4.126c.093-.093.168-.168.228-.23Z"
      />
    </svg>
  );
}

export function IconTrash(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.968 0h2.064c.439 0 .817 0 1.13.021.33.023.66.072.986.207a3 3 0 0 1 1.623 1.624c.135.326.185.656.208.986l.009.162H17a1 1 0 1 1 0 2h-1v10.842c0 .526 0 .98-.03 1.354-.033.395-.104.787-.297 1.166a3.003 3.003 0 0 1-1.312 1.311c-.378.193-.77.264-1.165.297-.374.03-.828.03-1.354.03H6.158c-.526 0-.98 0-1.354-.03-.395-.033-.788-.104-1.166-.297a3 3 0 0 1-1.311-1.311c-.193-.379-.264-.772-.296-1.167C2 16.82 2 16.365 2 15.839V5H1a1 1 0 1 1 0-2h3.012l.01-.162c.022-.33.071-.66.206-.986A3 3 0 0 1 5.852.228a3.07 3.07 0 0 1 .986-.207C7.15 0 7.529 0 7.968 0ZM6.015 3l.002-.026c.015-.228.042-.315.06-.356a1 1 0 0 1 .54-.542c.042-.017.128-.044.357-.06C7.21 2.002 7.52 2 8 2h2c.48 0 .79 0 1.026.017.228.015.315.042.357.06a1 1 0 0 1 .54.54c.018.042.045.13.06.357l.002.026h-5.97ZM4 5v10.8c0 .577 0 .949.024 1.232.022.271.06.372.085.422a1 1 0 0 0 .437.437c.049.025.15.063.42.085.284.023.655.024 1.23.024h5.607c.576 0 .947 0 1.23-.024.27-.022.371-.06.42-.085.189-.096.343-.25.438-.437.025-.05.063-.15.085-.421.023-.283.024-.654.024-1.23V5H4Z"
      />
    </svg>
  );
}
