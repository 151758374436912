import { FC, type MouseEventHandler } from "react";
import { GoArrowRight } from "react-icons/go";

import { classNames, compactJoin } from "../../../lib/utils";
import { BusinessScoresDetailedScoresLeadershipPresence } from "../../../Services/Types";
import { getCopyLinkText } from "../../../Utils/risk-summary";
import { Button } from "../../ui/button";
import { Dialog, DialogContent, DialogTrigger } from "../../ui/dialog";
import { IconFile, IconLink, IconTrash } from "../../ui/icon";
import { getRiskScoreStatus, RiskScore } from "../../ui/risk-score";
import { ScrollArea } from "../../ui/scroll-area";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";

const shouldShowTemporarilyUnimplementedActions = false;

export type LeadershipPresenceSectionProps = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  sectionDetails: BusinessScoresDetailedScoresLeadershipPresence;
};

export const LeadershipPresenceSection: FC<LeadershipPresenceSectionProps> = ({
  tooltipId,
  onCopyLink,
  sectionDetails,
}) => (
  <div className="grid gap-5 md:grid-cols-2">
    {
      // TODO: domanskyi - fix typing
      // @ts-ignore
      sectionDetails.person_details.map((item) => {
        if (!item.name || !item.title || !item.score || !item.summary) {
          return null;
        }

        const scoreStatus = getRiskScoreStatus(item.score);

        return (
          <div
            key={compactJoin(["section", "leadership", "presence", item.name], "_")}
            className="grid content-between gap-4 overflow-hidden rounded-xl border"
          >
            <div className="grid gap-4 p-8 pb-0">
              <div className="relative grid items-center pr-14">
                <h4 className="my-1 w-full truncate text-xl font-bold text-theme-text-body">{item.name}</h4>

                <p className="w-full truncate text-base font-bold text-theme-text-body">{item.title}</p>

                {scoreStatus && <RiskScore size="sm" score={item.score} className="absolute right-0 top-0" />}
              </div>

              <p className="text-base font-normal text-theme-text-body">{item.summary}</p>
            </div>

            <Dialog>
              <DialogTrigger asChild={true}>
                <Button
                  type="button"
                  className={classNames(
                    "rounded-none bg-theme-background text-primary",
                    "hover:bg-primary-disabled hover:text-primary-hover",
                    "focus-visible:ring-theme-background active:bg-primary-disabled",
                  )}
                >
                  Show more
                  <GoArrowRight className="ml-3 size-5" />
                </Button>
              </DialogTrigger>

              <DialogContent className="sm:max-w-2xl">
                <ScrollArea className="-mx-16 h-full max-h-[calc(theme(height.dvh)-theme(height.32))] px-16">
                  <div className="grid gap-4 p-1">
                    <div className="relative grid items-center pr-14">
                      <h4 className="my-0.5 w-full truncate text-2xl font-bold text-theme-text-body">{item.name}</h4>

                      <p className="w-full truncate text-base font-bold text-theme-text-body">{item.title}</p>

                      {scoreStatus && <RiskScore size="sm" score={item.score} className="absolute right-0 top-0" />}
                    </div>

                    <p className="text-base font-normal text-theme-text-body">{item.summary}</p>

                    {!!item?.core_experiences?.length && (
                      <>
                        <h6 className="mt-4 text-xl font-bold text-theme-text-body">Core Experiences</h6>

                        <ul
                          className={classNames(
                            "relative grid gap-4 pl-5",
                            "before:absolute before:left-[3px] before:h-full before:w-px before:bg-primary-disabled",
                          )}
                        >
                          {
                            //@ts-ignore
                            item.core_experiences.map((source) => (
                              <li
                                key={compactJoin(["core", "experiences", "source", source], "_")}
                                className={classNames(
                                  "relative -ml-5 w-full truncate bg-white pl-5 text-base font-bold leading-tight text-theme-text-body",
                                  "before:absolute before:left-0 before:top-1/2 before:size-[7px] before:-translate-y-1/2 before:rounded-full before:bg-primary-disabled",
                                )}
                              >
                                {source}
                              </li>
                            ))
                          }
                        </ul>
                      </>
                    )}

                    {!!item?.source_url?.length && (
                      <>
                        <h6 className="mt-4 text-xl font-bold text-theme-text-body">Sources</h6>

                        <ul className="grid gap-2">
                          {
                            // @ts-ignore
                            item.source_url.map((source) => {
                              const shouldShowTooltip = tooltipId === source;
                              const copyLinkText = getCopyLinkText(shouldShowTooltip);

                              return (
                                <li
                                  key={compactJoin(["source", "url", source], "_")}
                                  className={classNames(
                                    "grid gap-5",
                                    shouldShowTemporarilyUnimplementedActions
                                      ? "grid-cols-[1fr,repeat(3,minmax(0,auto))]"
                                      : "grid-cols-[1fr,repeat(1,minmax(0,auto))]",
                                  )}
                                >
                                  <a
                                    href={source}
                                    className="w-full max-w-fit truncate text-base font-normal text-primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {source}
                                  </a>

                                  <TooltipProvider>
                                    <Tooltip
                                      defaultOpen={false}
                                      {...(shouldShowTooltip && {
                                        open: true,
                                      })}
                                    >
                                      <TooltipTrigger asChild={true}>
                                        <Button
                                          type="button"
                                          variant="icon"
                                          size="icon"
                                          onClick={onCopyLink({
                                            id: source,
                                            value: source,
                                          })}
                                        >
                                          <IconLink />
                                          <span className="sr-only">{copyLinkText}</span>
                                        </Button>
                                      </TooltipTrigger>

                                      <TooltipContent>{copyLinkText}</TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>

                                  {shouldShowTemporarilyUnimplementedActions && (
                                    <>
                                      <Button type="button" variant="icon" size="icon">
                                        <IconFile />
                                        <span className="sr-only">Add Note</span>
                                      </Button>

                                      <Button
                                        type="button"
                                        variant="icon"
                                        size="icon"
                                        className="hover:text-status-high focus-visible:text-status-high active:text-status-high"
                                      >
                                        <IconTrash />
                                        <span className="sr-only">Remove</span>
                                      </Button>
                                    </>
                                  )}
                                </li>
                              );
                            })
                          }
                        </ul>
                      </>
                    )}
                  </div>
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </div>
        );
      })
    }
  </div>
);
