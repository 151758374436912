import PageFooter from "../Layout/pageFooter";
import PageHeader from "../Layout/pageHeader";

export default function PrivacyPolicy() {
  return (
    <div className="bg-neutral-50 text-left font-hanken text-base text-secondary">
      <PageHeader pageTitle="Privacy Policy" />
      <div className="px-40 py-20"></div>
      <PageFooter />
    </div>
  );
}
