import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { classNames } from "../../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none dark:focus-visible:ring-gray-300",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-white hover:bg-primary-hover active:bg-primary-pressed focus-visible:ring-primary disabled:bg-primary-disabled dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90",
        secondary:
          "bg-white text-theme-text-body border border-theme-border hover:border-primary-hover active:border-primary-pressed focus-visible:ring-theme-border disabled:bg-primary-disabled disabled:border-primary-disabled disabled:text-white dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50",
        destructive:
          "bg-[#f00] text-white hover:bg-[#d90101] active:bg-[#c00000] focus-visible:ring-[#f00] disabled:bg-[#fca9a9] dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90",
        ghost:
          "hover:bg-gray-100 hover:text-gray-900 disabled:opacity-50 dark:hover:bg-gray-800 dark:hover:text-gray-50",
        link: "text-gray-900 underline-offset-4 hover:underline disabled:opacity-50 dark:text-gray-50",
        icon: "text-theme-text-input hover:text-primary-hover focus-visible:text-primary-hover focus-visible:ring-0 active:text-primary-pressed",
      },
      size: {
        default: "h-10 px-6 py-0",
        sm: "h-9 rounded-md px-3 text-xs",
        // ToDo: Refactor Icon Button.
        icon: "size-6 p-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return <Comp className={classNames(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
