type PageFooterProps = {
  displayContact?: boolean;
};

export default function PageFooter({ displayContact = true }: PageFooterProps) {
  return (
    <>
      {displayContact && (
        <div className="flex flex-wrap justify-center px-6 py-8 md:justify-between md:gap-x-[4%] md:px-[10%] md:py-12">
          <div className="flex w-full flex-row items-center justify-center rounded bg-lavender px-6 py-3 text-primary-hover md:justify-between md:py-3">
            <span className="relative inline leading-6">
              If you have further questions, contact us. <strong>We're here to help you!</strong>
            </span>

            <a
              href="/chat"
              className="flex flex-col items-center justify-center rounded text-center text-xs text-neutral-50 no-underline"
            >
              <div className="flex flex-row items-center justify-center rounded bg-primary px-4 py-2">
                <div className="flex h-6 flex-row items-center justify-center">
                  <strong className="relative leading-4">Contact us</strong>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}

      <footer className="grid h-14 px-4">
        <section className="mx-auto flex w-full max-w-6xl items-center justify-between gap-4">
          <span className="text-xs font-medium leading-6 text-theme-text-body">
            &copy; {new Date().getFullYear()} BlueArc Systems, Inc. All rights reserved.
          </span>

          <div className="flex gap-8">
            <a href="/terms-of-use" className="text-xs font-bold leading-6 text-theme-text-body no-underline">
              Terms of Use
            </a>

            <a href="/privacy-policy" className="text-xs font-bold leading-6 text-theme-text-body no-underline">
              Privacy Policy
            </a>
          </div>
        </section>
      </footer>
    </>
  );
}
