import { FC, type MouseEventHandler } from "react";

import useLayoutVersion from "../../../../hooks/useLayoutVersion";
import { mergeDetailsByVisibleValue } from "../../../../Utils/risk-summary";
import { ScoreCategoriesV2 } from "./v2";
import { ScoreCategoriesV3 } from "./v3";

export type ScoreCategoriesVariantsProps = {
  tooltipId: string;
  onCopyLink: (params: { id: string; value: string }) => MouseEventHandler<HTMLButtonElement>;
  copiedText: string;
  // TODO: domanskyi - fix typing!!!!!!!!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedDetails: any;
  isGroupedDetailsExpanded: (title: string) => boolean;
  onToggleExpandedDetailsBlock: (title: string) => void;
};

export const ScoreCategoriesVariants: FC<ScoreCategoriesVariantsProps> = (props) => {
  const { version } = useLayoutVersion();

  switch (version) {
    case 1:
    default:
      return <ScoreCategoriesV2 {...props} groupedDetails={mergeDetailsByVisibleValue(props.groupedDetails)} />;
    case 2:
      return <ScoreCategoriesV3 {...props} />;
  }
};
