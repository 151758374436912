import { FC } from "react";

import { RISKS } from "../../constants/risk-summary";
import { classNames, compactJoin, isUrl } from "../../lib/utils";
import { getHostnameFromURL } from "../../lib/utils";
import type { BusinessScoresDetailedScoresOnlinePresence } from "../../Services/Types";
import { TDetailsEntity } from "../../Utils/risk-summary";
import { getRiskScoreStatus } from "../ui/risk-score";

const DetailRow: FC<{
  detail: TDetailsEntity;
  tooltipId: string | null;
  copiedText: string | null;
  getCopyLinkText: (v: boolean) => void;
}> = ({ detail, tooltipId, copiedText, getCopyLinkText }) => {
  const detailStatus = typeof detail?.score === "number" && getRiskScoreStatus(detail.score);
  const detailUniqueId = compactJoin([detail.attribute_enum, detail.visible_value]);

  const attributeEnums: BusinessScoresDetailedScoresOnlinePresence["details"][number]["attribute_enum"][] = [
    "WEBSITE",
    "SOCIAL_LINKS",
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldShowEntireLink = attributeEnums.includes(
    //@ts-ignore
    detail.attribute_enum,
  );
  const shouldShowTooltip = tooltipId === detailUniqueId && copiedText === detail.source_url;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyLinkText = getCopyLinkText(shouldShowTooltip);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.25rem 0rem",
        }}
      >
        {detail?.visible_value?.startsWith("http") ? (
          <a href={detail.visible_value} className="underline">
            <p className="my-1.5 w-full truncate">{getHostnameFromURL(detail.visible_value)}</p>
          </a>
        ) : (
          <p className="my-1.5 w-full truncate">{getHostnameFromURL(detail.visible_value)}</p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          {isUrl(detail.source_url) ? (
            <a href={detail.source_url} className="my-1.5 truncate underline" target="_blank" rel="noopener noreferrer">
              {getHostnameFromURL(detail.source_url)}
            </a>
          ) : (
            <p
              style={{
                textAlign: "right",
                maxWidth: "30%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {getHostnameFromURL(detail.source_url)}
            </p>
          )}
          {detailStatus && !!RISKS[detailStatus] && (
            <p
              className={classNames(
                "flex h-6 w-20 items-center justify-center rounded-s-full font-bold text-white",
                RISKS[detailStatus].backgroundColor,
              )}
              style={{
                maxWidth: "3rem",
              }}
            >
              {detail.score}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          maxWidth: "70%",
        }}
        className="text-sm text-gray-500"
      >
        <p>{detail.snippet}</p>
      </div>
    </div>
  );
};

export default DetailRow;
