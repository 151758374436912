import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import BlueArcLogo from "../../../assets/BlueArcLogo.svg";
import chatIcon from "../../../assets/chat-icon.svg";
import PatternBlue from "../../../assets/pattern-blue.svg";
import PatternGreen from "../../../assets/pattern-green.svg";
import QuestionGreenIcon from "../../../assets/question-green.svg";
import { Button } from "../../../components/ui/button";
import { firebaseAuth } from "../../../lib/firebase/auth";
import { classNames } from "../../../lib/utils";

type PageHeaderProps = {
  pageTitle?: string;
  pageSubTitle?: string;
  colorTheme?: "green" | "blue";
};

export default function PageHeader({ pageTitle, pageSubTitle, colorTheme = "green" }: PageHeaderProps) {
  const { pathname } = useLocation();

  const canSignOut = useMemo(() => !!firebaseAuth.currentUser && pathname.includes("/client"), [pathname]);

  const onSignOut = useCallback(async () => {
    await firebaseAuth.signOut();
  }, []);

  return (
    <>
      <header className="sticky top-0 z-20 grid h-16 bg-secondary px-4 shadow-lg shadow-black/10">
        <section className="mx-auto flex w-full max-w-6xl items-center justify-between gap-4">
          <a
            href="https://bluearc.ai"
            className="flex items-center gap-2 text-3xl text-white no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-12" alt="BlueArc Logo" src={BlueArcLogo} />
            BlueArc
          </a>

          {canSignOut && (
            <Button type="button" onClick={onSignOut}>
              Sign Out
            </Button>
          )}
        </section>
      </header>

      {pageTitle && (
        <div
          className={classNames(
            "flex grow flex-row justify-between",
            colorTheme === "blue" ? "bg-lavender text-primary" : "bg-whitesmoke text-teal-100",
          )}
        >
          <div className="flex flex-col items-start justify-start gap-y-2 py-10 pl-6 pr-16 md:pl-[10%] md:pr-0">
            <div className="flex flex-row items-center justify-start gap-x-2">
              <img
                className="relative size-9 shrink-0"
                alt=""
                src={colorTheme === "blue" ? chatIcon : QuestionGreenIcon}
              />

              <div className="relative text-5xl font-black">{pageTitle}</div>
            </div>

            <div className="flex flex-row items-center justify-start text-2xl">
              <b className="relative leading-8">{pageSubTitle}</b>
            </div>
          </div>

          <div
            className="hidden h-56 w-80 flex-col items-end justify-items-end md:flex"
            style={{
              backgroundImage: `url(${colorTheme === "blue" ? PatternBlue : PatternGreen})`,
            }}
          ></div>
        </div>
      )}
    </>
  );
}
