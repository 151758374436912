import { FC } from "react";
import { GoChevronDown } from "react-icons/go";

import { classNames } from "../../lib/utils";

type ShowMoreButtonProps = {
  onClick: () => void;
  isShowingMore: boolean;
};

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({ onClick, isShowingMore }) => (
  <span className="flex cursor-pointer flex-row items-center text-sm underline" onClick={onClick}>
    <GoChevronDown
      className={classNames(
        "size-4 shrink-0 text-current transition-transform duration-300",
        isShowingMore ? "rotate-180" : "",
      )}
      style={{
        marginRight: ".125rem",
      }}
    />
    Show {isShowingMore ? "less" : "more"}
  </span>
);
