import { FunctionComponent } from "react";

import ErrorIcon from "../../../assets/api-error.svg";
import bottomCorner from "../../../assets/bottom-corner.svg";
import topCorner from "../../../assets/top-corner.svg";
import PageFooter from "../../Layout/pageFooter";
import PageHeader from "../../Layout/pageHeader";

const APIErrorPage: FunctionComponent = () => {
  const refresh = () => window.location.reload();

  return (
    <div
      className="bg-neutral-50 bg-right-bottom bg-no-repeat text-left text-base text-secondary"
      style={{ backgroundImage: `url(${bottomCorner})` }}
    >
      <PageHeader />

      <div
        className="flex grow flex-row justify-center bg-left-top bg-no-repeat pb-80 pt-72"
        style={{ backgroundImage: `url(${topCorner})` }}
      >
        <div className="flex flex-col items-center justify-center gap-8 text-center text-secondary">
          <div className="flex flex-col items-center justify-start gap-6">
            <img className="relative size-16 shrink-0 overflow-hidden" alt="" src={ErrorIcon} />
            <div className="flex flex-col items-start justify-start gap-2">
              <b className="relative self-stretch leading-9">Something went wrong</b>
              <div className="relative self-stretch text-base font-medium leading-6 text-dimgray">
                Please try again.
              </div>
            </div>
          </div>
          <div className="flex h-14 shrink-0 flex-col items-center justify-center overflow-hidden rounded text-base text-primary">
            <div className="flex flex-row items-center justify-center self-stretch px-6 py-4">
              <div className="flex h-6 flex-row items-center justify-center">
                <b onClick={refresh} data-testid="Submit" className="relative cursor-pointer leading-6">
                  Reload page
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageFooter displayContact={false} />
    </div>
  );
};

export default APIErrorPage;
