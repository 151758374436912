import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import ApiConfig from "../../Services/ApiConfig";

const RegVouch = (): any => {
  const { token, action } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    regVouchDetails();
  });

  const regVouchDetails = async (): Promise<any> => {
    const apiURL = `${ApiConfig.registerVoucher}?token=${token}&relcode=${action}`;
    console.log(apiURL);
    try {
      const res = await axios.post(apiURL);
      if (res && res.status === 200) {
        setTimeout(function () {
          window.location.href = "https://bluearc.ai";
        }, 10000);
      } else {
        navigate("/api-error");
      }
    } catch (e: any) {
      console.error(e);
      navigate("/not-found-error");
    }
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div
        data-testid="loading-element"
        className="inline-block size-24 animate-spin rounded-full border-4 border-slate-400 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};

export default RegVouch;
