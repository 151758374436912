const Footer = () => {
  return (
    <footer>
      <div className="relative top-[790px] mt-32 flex flex-col justify-between pb-4 pl-16 md:top-16 md:flex-row md:pb-0 md:pl-10">
        <div className="font-hanken text-[12px] leading-[18px] text-dimgray md:float-left">
          <label className="pr-1">©</label>
          <label>{`${new Date().getFullYear()} BlueArc Systems, Inc. All rights reserved.`}</label>
        </div>
        <div className="ml-20 flex flex-row md:ml-0">
          <a href="/terms-of-use" className="text-[12px] font-[700] leading-[18px] text-primary">
            Terms of Use
          </a>
          <a href="/privacy-policy" className="ml-4 text-[12px] font-[700] leading-[18px] text-primary">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
