const Env = JSON.parse(JSON.stringify(process.env));
const ApiUrl = Env["API_URL"] || "https://";

console.log("Environment Variables:", process.env);

const ApiConfig = {
  verifyToken: ApiUrl + "verify-token-ivjxdds3ha-uc.a.run.app",
  registerVoucher: ApiUrl + "register-vouch-ivjxdds3ha-uc.a.run.app",
  businessScores: `${ApiUrl}${process.env.REACT_APP_ENDPOINT_BUSINESS_SCORES}`,
  businessScoresV2: `${ApiUrl}${process.env.REACT_APP_ENDPOINT_BUSINESS_SCORES_V2}`,
  userBatchesHistory: `${ApiUrl}${process.env.REACT_APP_ENDPOINT_USER_BATCHES_HISTORY}`,
  signUploadUrl: `${ApiUrl}${process.env.REACT_APP_ENDPOINT_SIGN_UPLOAD_URL}`,
};

export default ApiConfig;
