import type { BusinessScoresDetailedScoresOnlinePresence } from "../Services/Types";

export type TDetailsEntity = {
  source_url: string;
  attribute_enum: string;
  subcategory: string;
  value: string;
  score: number;
  visible_value: string;
  snippet: string;
};

export function groupDataByAttribute(
  details: BusinessScoresDetailedScoresOnlinePresence["details"],
): Record<string, BusinessScoresDetailedScoresOnlinePresence["details"]> {
  return details.reduce(
    (previous, current) => ({
      ...previous,
      [current.attribute]:
        current.type === "TYPE_MULTI_VALUE"
          ? current.data.map((data) => ({
              ...data,
              attribute_enum: current.attribute_enum,
            }))
          : current.type === "TYPE_SINGLE"
            ? [current]
            : [],
    }),
    {},
  );
}

export const mergeDetailsByVisibleValue = (
  groupedDetails: Record<string, Array<TDetailsEntity>>,
): Record<string, Array<[string, Array<TDetailsEntity>]>> => {
  const groupedDetailsArray = Object.entries(groupedDetails);

  return groupedDetailsArray.reduce((acc, [groupName, details]) => {
    const mergedDetails: Record<string, Array<TDetailsEntity>> = {};
    for (const detail of details) {
      if (!mergedDetails[detail.visible_value]) {
        mergedDetails[detail.visible_value] = [];
      }
      mergedDetails[detail.visible_value].push(detail);
    }

    return {
      ...acc,
      [groupName]: Object.entries(mergedDetails).sort(([, a], [, b]) => b.length - a.length),
    };
  }, {});
};

export function getCopyLinkText(isCopied: boolean) {
  return isCopied ? "Link Copied!" : "Copy Link";
}

export function isNumber(value: unknown): boolean {
  return typeof value === "number" && !isNaN(value);
}
