import { ScoreCategoryKey } from "../Services/Types";

export const RISKS = {
  good: {
    title: "Well-Established",
    description: "This business is credible",
    textColor: "text-status-low",
    backgroundColor: "bg-status-low",
    beforeBackgroundColor: "before:bg-status-low",
  },
  unknown: {
    title: "Not Well-Established",
    description: "There is not enough footprint to know this business is good, and it could be fraud",
    textColor: "text-status-neutral",
    backgroundColor: "bg-status-neutral",
    beforeBackgroundColor: "before:bg-status-neutral",
  },
  risky: {
    title: "Known Risk",
    description: "There is evidence indicating high risk for this business",
    textColor: "text-status-high",
    backgroundColor: "bg-status-high",
    beforeBackgroundColor: "before:bg-status-high",
  },
};

export const SCORE_CATEGORIES: Record<
  ScoreCategoryKey,
  {
    title: string;
  }
> = {
  COMPOSITE_BUSINESS_RISKS: {
    title: "Composite Business Risk",
  },
  PRODUCTS_SERVICES: {
    title: "Products & Services",
  },
  BUSINESS_PRESENCE: {
    title: "Business Presence",
  },
  ONLINE_PRESENCE: {
    title: "Online Presence",
  },
  HISTORICAL_PRESENCE: {
    title: "Historical Presence",
  },
  LEADERSHIP_PRESENCE: {
    title: "Leadership Presence",
  },
};
